export const landingColumns = [
    {
        field: "landingImage",
        headerName: "Landing Image",
        width: 390,
        renderCell: (params) => (
            <img src={params.value} alt="Landing" style={{ width: '100%', height: 'auto' }} />
        ),
    },
    {
        field: "landingMainTitle",
        headerName: "Landing Main Title",
        width: 390,
    },
    {
        field: "landingSecondTitle",
        headerName: "Landing Second Title",
        width: 390,
    },
];
export const HeroColumns = [
    {
        field: "heroMainTitle",
        headerName: "Hero Main Title",
        width: 390,
    },
    {
        field: "heroMainTitleHighlight",
        headerName: "Hero Main Title Highlight",
        width: 390,
    },
    {
        field: "heroDesc",
        headerName: "Hero Desc",
        width: 390,
    },
];

export const ExperienceColumns = [
    {
        field: "experienceMainTitle",
        headerName: "Experience Main Title",
        width: 390,
    },
    {
        field: "experienceSecondTitle",
        headerName: "Experience Second Title",
        width: 390,
    },
    {
        field: "experienceDesc",
        headerName: "Experience Desc",
        width: 390,
    }
];

export const ExperienceNumbersColumns = [
    {
        field: "successfullTrip",
        headerName: "Successfull Trip",
        width: 390,
    },
    {
        field: "regularClients",
        headerName: "Regular Clients",
        width: 390,
    },
    {
        field: "yearsExperience",
        headerName: "Years Experience",
        width: 390,
    },
];