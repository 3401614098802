export const userInputs = [
  {
    id: "username",
    label: "Username",
    type: "text",
    placeholder: "john_doe",
  },
  {
    id: "email",
    label: "Email",
    type: "mail",
    placeholder: "john_doe@gmail.com",
  },
  {
    id: "password",
    label: "Password",
    type: "password",
  },
  // {
  //   id: "role",
  //   label: "Role",
  //   type: "text",
  //   placeholder: "User Role",
  // },
];

export const productInputs = [
  {
    id: 1,
    label: "Title",
    type: "text",
    placeholder: "Apple Macbook Pro",
  },
  {
    id: 2,
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
  {
    id: 3,
    label: "Category",
    type: "text",
    placeholder: "Computers",
  },
  {
    id: 4,
    label: "Price",
    type: "text",
    placeholder: "100",
  },
  {
    id: 5,
    label: "Stock",
    type: "text",
    placeholder: "in stock",
  },
];

export const replyInputs = [
  {
    id: "reply",
    label: "Reply",
    type: "text",
    placeholder: "Enter Your Reply",
  },
];

export const ContactInfoInputs = [
  {
    id: "phone",
    label: "Phone Number",
    type: "text",
    placeholder: "Enter Your Phone Number",
  },
  {
    id: "email",
    label: "Email",
    type: "text",
    placeholder: "Enter Your Email",
  },
  {
    id: "showInHomePage",
    label: "Show In Home Page",
    type: "checkbox"
  },
];

export const CountriesInputs = [
  {
    id: "country",
    label: "Country",
    type: "text",
    placeholder: "Enter Country Name",
  },
  {
    id: "imgTitle",
    label: "Image Title",
    type: "text",
    placeholder: "Enter Image Title",
  },
  {
    id: "imgDesc",
    label: "Description",
    type: "text",
    placeholder: "Enter Image Description",
  },  
  {
    id: "overview_pragraph1",
    label: "Overview First Pragraph",
    type: "text",
    placeholder: "Enter Overview First Pragraph",
  },
  {
    id: "overview_pragraph2",
    label: "Overview Second Pragraph",
    type: "text",
    placeholder: "Enter Overview Second Pragraph",
  },
];
export const LandingHomeInputs = [
  {
    id: "landingMainTitle",
    label: "Landing Main Title",
    type: "text",
    placeholder: "Enter Landing Main Title",
  },
  {
    id: "landingSecondTitle",
    label: "Landing Second Title",
    type: "text",
    placeholder: "Enter Landing Second Title",
  },
];
export const HeroHomeInputs = [
  {
    id: "heroMainTitle",
    label: "Hero Main Title",
    type: "text",
    placeholder: "Hero Main Title"
  },
  {
    id: "heroMainTitleHighlight",
    label: "Main Title Highlight",
    type: "text",
    placeholder: "Enter Main Title Highlight",
  },
  {
    id: "heroDesc",
    label: "Hero Desc",
    type: "text",
    placeholder: "Enter Hero Desc",
  },
];
export const ExperienceHomeInputs = [
  {
    id: "experienceMainTitle",
    label: "Experience Main Title",
    type: "text",
    placeholder: "Experience Main Title"
  },
  {
    id: "experienceSecondTitle",
    label: "Experience Second Title",
    type: "text",
    placeholder: "Enter Experience Second Title",
  },
  {
    id: "experienceDesc",
    label: "Experience Desc",
    type: "text",
    placeholder: "Experience Desc",
  },
  
];

export const ExperienceNumbersHomeInputs = [
  {
    id: "successfullTrip",
    label: "Successfull Trip",
    type: "Number",
  },
  {
    id: "regularClients",
    label: "Regular Clients",
    type: "Number",
  },
  {
    id: "yearsExperience",
    label: "Years Experience",
    type: "Number",
  },
]

export const NewsLetterInputs = [
  {
    id: "title",
    label: "Title",
    type: "text",
    placeholder: "Enter Title",
  },
  {
    id: "desc",
    label: "Description",
    type: "text",
    placeholder: "Enter Description",
  },
];