import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./gallery.scss"
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { useState } from "react";
import { BASE_URL, CLOUDINARY_URL } from "../../utils/config";
import axios from "axios";

const Gallery = () => {
    const [data, setData] = useState([]);
    const [files, setFiles] = useState(['', '', '', '', '', '', '', '',]);

    useEffect(() => {
        axios.get(`${BASE_URL}/gallery`)
            .then(res => {
                console.log(res.data.data)
                setData(res.data.data)
                setFiles(res.data.data.map(item => item.image))
            })
            .catch(err => { alert(err); console.log(err) })
    }, [])

    const handleFileChange = (e, index) => {
        const newFiles = [...files];
        newFiles[index] = e.target.files[0];
        setFiles(newFiles);

        const reader = new FileReader();
        reader.onload = (event) => {
            const imageDataUrl = event.target.result;
            const imgElement = document.getElementById(`img-${index}`);
            if (imgElement) {
                imgElement.src = imageDataUrl;
            }
        };
        reader.readAsDataURL(e.target.files[0]);
    };


    const handleClick = async (e) => {
        e.preventDefault();
        try {
            const photos = await Promise.all(
                Object.values(files).map(async (file) => {
                    const data = new FormData();
                    data.append("file", file);
                    data.append("upload_preset", "upload")
                    try {
                        const uploadRes = await axios.post(CLOUDINARY_URL, data);
                        const { url } = uploadRes.data;
                        return url;
                    } catch (error) {
                        console.error('Error uploading image to Cloudinary:', error);
                    }
                }));
            console.log(photos)
            await Promise.all(
                data.map(async (item, index) => {
                    console.log(item)
                    await axios.put(`${BASE_URL}/gallery/${item._id}`, { image: photos[index] });
                })
            );
            alert('Gallery images updated successfully');
        } catch (error) {
            console.log(error);
            alert('Failed to upload images, try again');
        }
    };


    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
                <Navbar />

                <div className="datatableHome">

                    <div className="datatableTitle">
                        <div className="datatableTitleHome">
                            Gallery
                        </div>
                        <button className="link-gallery" onClick={handleClick}>save</button>
                    </div>
                    <hr />
                    <br />
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 768: 3, 992: 4 }}>
                        <Masonry gutter='1rem'>
                            {
                                data.map((item, index) => (
                                    <div key={index}>
                                        <img className='masonry__img' src={item.image || 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'}
                                            id={`img-${index}`}
                                            key={index} alt=''
                                            style={{ 'width': '100%', 'display': 'block', 'borderRadius': '10px' }}
                                        />
                                        <input
                                            type="file"
                                            id={`file-${index}`}
                                            onChange={(e) => handleFileChange(e, index)}
                                        />
                                    </div>
                                ))
                            }
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
                <div style={{ height: '400px' }}>ss</div>
            </div>
        </div>
    );
};

export default Gallery;
