import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ModeOfTravel from "@mui/icons-material/ModeOfTravel";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ReviewsIcon from '@mui/icons-material/Reviews';
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Link } from "react-router-dom";

import { BASE_URL } from "../../utils/config";
import axios from "axios";
import { useState } from "react";

const Widget = ({ type }) => {
  let data;

  const [userCount, setUserCount] = useState(0);
  const [toursCount, setToursCount] = useState(0);
  const [bookingsCount, setBookingsCount] = useState(0);
  const [reviewsCount, setReviewsCount] = useState(0);

  let users = axios.get(`${BASE_URL}/users`).then(function (response) {
    // handle success
    setUserCount(response.data.data.length);
    
    console.log(userCount);
  });

  let tours = axios.get(`${BASE_URL}/tours`).then(function (response) {
    // handle success
    setToursCount(response.data.data.length);
    
    console.log(toursCount);
  });

  let bookings = axios.get(`${BASE_URL}/booking`).then(function (response) {
    // handle success
    setBookingsCount(response.data.data.length);
    
    console.log(bookingsCount);
  });

  let reviews = axios.get(`${BASE_URL}/review`).then(function (response) {
    // handle success
    setReviewsCount(response.data.data.length);
    
    console.log(reviewsCount);
  });

  let userAmount = userCount;
  let tourAmount = toursCount;
  let bookingAmount = bookingsCount;
  let reviewsAmount = reviewsCount;
  //temporary
  let amount = 100;
  let diff = 20;

  switch (type) {
    case "user":
      data = {
        title: "USERS",
        isMoney: false,
        link: <Link to={`/users`} style={{textDecoration: "auto"}}>View all Users</Link>,
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "Tours",
        isMoney: false,
        link: <Link to={`/tours`} style={{textDecoration: "auto"}}>View all Tours</Link>,
        icon: (
          <ModeOfTravel
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "Bookings",
        isMoney: false,
        link: <Link to={`/booking`} style={{textDecoration: "auto"}}>View all Bookings</Link>,
        icon: (
          <CreditCardIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "balance":
      data = {
        title: "Reviews",
        isMoney: false,
        link: <Link to={`/review`} style={{textDecoration: "auto"}}>View all Reviews</Link>,
        icon: (
          <ReviewsIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          
          {data.isMoney && "$"} 

          {data.title == "USERS" ? (
            userAmount
          ) : data.title == "Tours" ? (
            tourAmount
          ) : data.title == "Bookings" ?(
            bookingAmount
          ) :  data.title == "Reviews" ?(
            reviewsAmount
          ) : (
            amount
          )}

        </span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
          {diff} %
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
