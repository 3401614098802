import "./home.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, CLOUDINARY_URL } from "../../utils/config";
import { useNavigate, useParams } from "react-router-dom";

const UpdateHome = ({ LandingHomeInputs, HeroHomeInputs, ExperienceHomeInputs, ExperienceNumbersHomeInputs }) => {
    const navigate = useNavigate();
    const params = useParams();
    const [landing, setLanding] = useState([])
    const [hero, setHero] = useState({});
    const [exp, setExp] = useState({})
    const [files, setFiles] = useState(['', '', ''])

    const [landingFormError, setLandingFormError] = useState({
        landingImage: null,
        landingMainTitle: null,
        landingSecondTitle: null,
    })
    const [heroFormError, setHeroFormError] = useState({
        heroMainTitle: null,
        heroMainTitleHighlight: null,
        heroDesc: null,
    });
    const [expFormError, setExpFormError] = useState({
        experienceMainTitle: null,
        experienceSecondTitle: null,
        experienceDesc: null,
        successfullTrip: null,
        regularClients: null,
        yearsExperience: null,
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/home/`);
                const res = response.data[0];
                const { landingSection, heroSection, experience } = res;
                setLanding(landingSection);
                // setFiles(landing.map(item => item.landingImage))
                setHero(heroSection)
                setExp(experience)
                console.log(res)
                // console.log('files',files)
            } catch (error) {
                console.error('Error fetching list info:', error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        setFiles(landing.map(item => item.landingImage))
    }, [landing]);

    const handleChange = (e, index) => {
        const { name, value, type } = e.target;

        if (type === 'file') {
            const newFiles = [...files];
            newFiles[index] = e.target.files[0];
            setFiles(newFiles);

            // Update the image source
            const reader = new FileReader();
            reader.onload = () => {
                const imageDataUrl = reader.result;
                const imgElement = document.getElementById(`image-${index}`);
                if (imgElement) {
                    imgElement.src = imageDataUrl;
                }
            };
            reader.readAsDataURL(e.target.files[0]);
        } else if (name.startsWith('landing')) {
            // Landing section
            if (value === '') {
                setLandingFormError((prevErrors) => ({
                    ...prevErrors,
                    [index]: {
                        ...prevErrors[index],
                        [name]: 'This field is required'
                    }
                }));
            } else {
                setLanding((prevLanding) => [
                    ...prevLanding.slice(0, index),
                    {
                        ...prevLanding[index],
                        [name]: value
                    },
                    ...prevLanding.slice(index + 1)
                ]);
                setLandingFormError((prevErrors) => ({
                    ...prevErrors,
                    [index]: {
                        ...prevErrors[index],
                        [name]: null
                    }
                }));
            }

        } else if (name.startsWith('hero')) {
            // Hero section
            if (value === '') {
                setHeroFormError({
                    ...heroFormError,
                    [name]: 'This field is required'
                });
            } else {
                setHero({
                    ...hero,
                    [name]: value
                });
                setHeroFormError({
                    ...heroFormError,
                    [name]: null
                });
            }
        } else {
            // Experience section
            if (value === '') {
                setExpFormError({
                    ...expFormError,
                    [name]: 'This field is required'
                });
            } else {
                setExp({
                    ...exp,
                    [name]: value
                });
                setExpFormError({
                    ...expFormError,
                    [name]: null
                });
            }
        }
    };


    const handleClick = async (e) => {
        e.preventDefault();

        console.log('filessss', files)
        const photos = await Promise.all(
            Object.values(files).map(async (file) => {
                const data = new FormData();
                data.append("file", file);
                data.append("upload_preset", "upload")
                try {
                    const uploadRes = await axios.post(CLOUDINARY_URL, data);
                    const { url } = uploadRes.data;
                    return url;
                } catch (error) {
                    console.error('Error uploading image to Cloudinary:', error);
                }
            }));

        // Update landing section with new image URLs
        const updatedLanding = landing.map((item, index) => {
            return {
                ...item,
                landingImage: photos[index] || item.landingImage
            };
        });

        // Check for empty fields and set errors if necessary
        const landingEmptyFields = Object.values(updatedLanding).some(value => String(value).trim() === '');
        const heroEmptyFields = Object.values(hero).some(value => String(value).trim() === '');
        const expEmptyFields = Object.values(exp).some(value => String(value).trim() === '');

        setLandingFormError({
            landingImage: landingEmptyFields && 'Landing Image is required',
            landingMainTitle: landingEmptyFields && 'Landing Main Title is required',
            landingSecondTitle: landingEmptyFields && 'Landing Second Title is required',
        });

        setHeroFormError({
            heroMainTitle: heroEmptyFields && 'Hero Main Title is required',
            heroMainTitleHighlight: heroEmptyFields && 'Main Title Highlight is required',
            heroDesc: heroEmptyFields && 'Hero Description is required',
        });

        setExpFormError({
            experienceMainTitle: expEmptyFields && 'Experience Main Title is required',
            experienceSecondTitle: expEmptyFields && 'Experience Second Title is required',
            experienceDesc: expEmptyFields && 'Experience Description is required',
            successfullTrip: expEmptyFields && 'Successful Trip is required',
            regularClients: expEmptyFields && 'Regular Clients is required',
            yearsExperience: expEmptyFields && 'Years of Experience is required',
        });

        // If any section has empty fields, stop the process
        if (landingEmptyFields || heroEmptyFields || expEmptyFields) {
            return;
        }

        try {
            // Send update request with the updated landing section
            await axios.put(`${BASE_URL}/home/${params.id}`, { landingSection: updatedLanding, heroSection: hero, experience: exp });
            alert("Home info Updated Successfully");
            navigate('/home');
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="top">
                    <h1>Update Home Information</h1>
                </div>
                <div className="bottom">
                    <div className="right">
                        <form className="form">
                            {/* Landing */}
                            <div className="formInput">
                                <div className="title">Landing Section</div>
                                <div >
                                    {landing && landing.map((item, index) => {
                                        return (
                                            <div key={index} style={{ display: 'flex', paddingBottom: '1rem' }}>
                                                <div style={{ flex: '2' }}>
                                                    <img
                                                        src={files[index]}
                                                        className="landingImage"
                                                        id={`image-${index}`}
                                                    />
                                                    <input
                                                        type="file"
                                                        id={`file-${index}`}
                                                        onChange={(e) => handleChange(e, index)}
                                                        style={{ border: 'none' }}
                                                    />
                                                </div>
                                                <div style={{ flex: '8', paddingBottom: '2rem' }}>
                                                    {LandingHomeInputs.map((input) => (
                                                        <div className="formInput" key={input.id} style={{ paddingBottom: '2rem' }}>
                                                            <label htmlFor={input.id}>{input.label}</label>
                                                            <input
                                                                id={input.id}
                                                                name={input.id}
                                                                type={input.type}
                                                                placeholder={input.placeholder}
                                                                value={item[input.id]}
                                                                onChange={(e) => handleChange(e, index)}
                                                                style={{ width: '70% !important' }}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                            {/* <hr style={{margin: '1rem auto 0', borderColor: '#db9e36', width: '50%'}}/> */}
                            {/* heroSection */}
                            <div className="title">Hero Section</div>
                            <div style={{ display: 'flex' }}>
                                {HeroHomeInputs.map((input) => (
                                    <div className="formInput" key={input.id}>
                                        <label htmlFor={input.id}>{input.label}</label>
                                        <input
                                            id={input.id}
                                            name={input.id}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            value={hero[input.id]}
                                            onChange={handleChange}
                                            style={{ width: '70%' }}
                                        />
                                        {/* {listFormError[input.id] && <div className="errorMessage">{listFormError[input.id]}</div>} */}
                                    </div>
                                ))}
                            </div>
                            {/* <hr style={{margin: '1rem auto 0', borderColor: '#db9e36', width: '50%'}}/> */}
                            <br />
                            {/* experience */}
                            <div className="title">Experience Section</div>
                            <div style={{ display: 'flex' }}>
                                {ExperienceHomeInputs.map((input) => (
                                    <div className="formInput" key={input.id}>
                                        <label htmlFor={input.id}>{input.label}</label>
                                        <input
                                            id={input.id}
                                            name={input.id}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            value={exp[input.id]}
                                            onChange={handleChange}
                                            style={{ width: '70%' }}
                                        />
                                        {/* {listFormError[input.id] && <div className="errorMessage">{listFormError[input.id]}</div>} */}
                                    </div>
                                ))}
                            </div>
                            {/* experience Numbers*/}
                            <div style={{ display: 'flex' }}>
                                {ExperienceNumbersHomeInputs.map((input) => (
                                    <div className="formInput" key={input.id}>
                                        <label htmlFor={input.id}>{input.label}</label>
                                        <input
                                            id={input.id}
                                            name={input.id}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            min={0}
                                            value={exp[input.id]}
                                            onChange={handleChange}
                                            style={{ width: '70%' }}
                                        />
                                        {/* {listFormError[input.id] && <div className="errorMessage">{listFormError[input.id]}</div>} */}
                                    </div>
                                ))}
                            </div>


                            <button
                                type="submit"
                                onClick={handleClick}
                            >
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateHome;
