import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { BASE_URL } from "../../utils/config";
import { useEffect, useState } from "react";
import "./home.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axios from "axios";
import {
    landingColumns,
    HeroColumns,
    ExperienceColumns,
    ExperienceNumbersColumns
} from "./columns"

const HomeInfo = () => {
    const [list, setList] = useState({
        landingSection: [],
        heroSection: {},
        experience: {},
    });
    const [id, setId] = useState(0)

    useEffect(() => {
        axios.get(`${BASE_URL}/home`)
            .then(res => {
                setList(res.data[0]);
                setId(res.data[0]._id)
                console.log(id)
            })
            .catch(err => console.log(err))
    }, []);

    const generateUniqueId = () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    };

    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
                <Navbar />
                <div>
                    <div className="" style={{ paddingLeft: '20px', paddingTop: '20px', fontSize: '24px', color: 'gray', marginBottom: '0px' }}>
                        Home
                    </div>

                    <div className="datatableTitleHome" style={{ position: 'relative' }}>
                        <Link
                            to={`/home/update/${id}`}
                            className="link-Home"
                            style={{ position: 'absolute', right: '20px' }}>
                            Edit
                        </Link>
                    </div>
                    {/* Landing */}
                    <div className="datatableHome">
                        <div className="datatableTitleHome">
                            Landing Section
                        </div>
                        <DataGrid
                            className="datagrid"
                            rows={list.landingSection.map(row => ({ ...row, id: generateUniqueId() }))}
                            columns={landingColumns}
                            pageSize={3}
                            rowsPerPageOptions={[1, 3]}
                            checkboxSelection
                            getRowId={(row) => row.id}
                        />
                    </div>
                    <br />
                    {/* Hero */}
                    <div className="datatableHome">
                        <div className="datatableTitleHome">
                            Hero Section
                        </div>
                        <DataGrid
                            className="datagrid"
                            rows={[{ ...list.heroSection, id: generateUniqueId() }]}
                            columns={HeroColumns}
                            pageSize={1}
                            rowsPerPageOptions={[1]}
                            checkboxSelection
                            getRowId={(row) => row.id}
                        />
                    </div>
                    <br />
                    {/* Experience */}
                    <div className="datatableHome">
                        <div className="datatableTitleHome">
                            Experience Section
                        </div>
                        <DataGrid
                            className="datagrid"
                            rows={[{ ...list.experience, id: generateUniqueId() }]}
                            columns={ExperienceColumns}
                            pageSize={1}
                            rowsPerPageOptions={[1]}
                            checkboxSelection
                            getRowId={(row) => row.id}
                        />
                    </div>
                    <br />
                    {/* Experience Numbers */}
                    <div className="datatableHome">
                        <div className="datatableTitleHome">
                            Experience Numbers Section
                        </div>
                        <DataGrid
                            className="datagrid"
                            rows={[{ ...list.experience, id: generateUniqueId() }]}
                            columns={ExperienceNumbersColumns}
                            pageSize={1}
                            rowsPerPageOptions={[1]}
                            checkboxSelection
                            getRowId={(row) => row.id}
                        />
                    </div>
                    <br /><br /><br />
                </div>
            </div>
        </div>
    );
};

export default HomeInfo;
