import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { useNavigate, useParams } from "react-router-dom";

const NewsLetter = ({ inputs, title }) => {
    const navigate = useNavigate();
    const params = useParams();
    const [newsLetter, setnewsLetter] = useState({
        title: '',
        desc: '',
    });
    const [newsLetterFormError, setnewsLetterFormError] = useState({
        title: null,
        desc: null
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/newsLetter/`);
                const res = response.data[0];
                console.log(response)
                setnewsLetter({
                    title: res.title,
                    desc: res.desc,
                });
            } catch (error) {
                console.error('Error fetching news letter:', error);
            }
        };
        fetchData();
    }, [params.id]);

    const handleChange = (e) => {
        const { name, value } = e.target;


        if (value === '') {
            setnewsLetterFormError({
                ...newsLetterFormError,
                [name]: 'This field is required'
            });
        } else {
            setnewsLetter({
                ...newsLetter,
                [name]: value
            });
            setnewsLetterFormError({
                ...newsLetterFormError,
                [name]: null
            });

        }
    };

    const handleClick = async (e) => {
        e.preventDefault();
        const hasEmptyFields = Object.values(newsLetter).some(value => String(value).trim() === '');
        if (hasEmptyFields) {
            setnewsLetterFormError({
                title: String(newsLetter.title).trim() === '' ? 'title is required' : null,
                desc: String(newsLetter.desc).trim() === '' ? 'desc is required' : null
            });
            return;
        }
        try {
            await axios.put(`${BASE_URL}/newsLetter/${params.id}`, newsLetter);
            alert("News Letter Updated Successfully");
            navigate('/newsLetter');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="top">
                    <h1>{title}</h1>
                </div>
                <div className="bottom">
                    <div className="right">
                        <form>
                            {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label htmlFor={input.id}>{input.label}</label>
                                        <input
                                            id={input.id}
                                            name={input.id}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            value={newsLetter[input.id]}
                                            onChange={handleChange}
                                        />
                                    {newsLetterFormError[input.id] && <div className="errorMessage">{newsLetterFormError[input.id]}</div>}
                                </div>
                            ))}
                            <button
                                type="submit"
                                onClick={handleClick}
                            >
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsLetter;
