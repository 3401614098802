import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, tourColumns, userRows } from "../../datatablesource";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { BASE_URL } from "../../utils/config";
import axios from "axios";
import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { saveAs } from 'file-saver';

const Datatable = ({ columns }) => {
  const { user } = useContext(AuthContext);
  const currentUserEmail = user.email

  // const [data, setData] = useState(userRows);
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const [list, setList] = useState([]);

  let title = path;

  if (title === "tours") {
    title = "Tours";
  } else if (path === "users") {
    title = "Users";
  } else if (path === "booking") {
    title = "Bookings";
  } else if (path === "review") {
    title = "Reviews";
  } else if (path === "contactInfo") {
    title = "Contact Information";
  } else if (path === "countries") {
    title = "Countries";
  } else if (path === "newsLetter") {
    title = "News Letter";
  } else if (path === "newsLetterEmails") {
    title = "News Letter Emails";
  } else if (path === "cutomiseTrips") {
    title = "Cutomise Trips Requests";
  }

  // const { data: users, loading, error } = useFetch(`${BASE_URL}/users`);
  const { data: users, loading, error } = useFetch(`${BASE_URL}/${path}`);

  useEffect(() => {
    if (title === "Contact Information") {
      axios.get(`${BASE_URL}/contactInfo`)
        .then(res => {
          setList(res.data);
        })
    } else if (title === "Countries") {
      axios.get(`${BASE_URL}/countries`)
        .then(res => {
          setList(res.data);
        })
    }
    else if (title === "News Letter") {
      axios.get(`${BASE_URL}/newsLetter`)
        .then(res => {
          setList(res.data);
        })
    }
    else if (title === "News Letter Emails") {
      axios.get(`${BASE_URL}/newsLetterEmails`)
        .then(res => {
          setList(res.data);
        })
    }
    else if (title === "Cutomise Trips Requests") {
      axios.get(`${BASE_URL}/cutomiseTrips`)
        .then(res => {
          setList(res.data);
        })
    }
    else {
      setList(users);
    }
  }, [users, title]);

  const handleDelete = async (id) => {
    // setData(users.filter((item) => item._id !== id));
    await axios.delete(`${BASE_URL}/${path}/${id}`);
    setList(list.filter((item) => item._id !== id));
    try {
    } catch (error) { }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div>
              {title === "Tours" ? (
                <Link
                  to={`/tours/update/${params.row._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="viewButton">Edit</div>
                </Link>
              ) : (
                <div></div>
              )}

              {title === "Users" && params.row.email !== 'admin@mea-travel.com' ? (
                <Link
                  to={`/users/update/${params.row._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="viewButton">Edit</div>
                </Link>
              ) : (
                title === "Users" && currentUserEmail === 'admin@mea-travel.com' ? (
                  <Link
                    to={`/users/update/${params.row._id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="viewButton">Edit</div>
                  </Link>
                ) : (
                  <div></div>
                )
              )}


              {title === "Countries" ? (
                <Link
                  to={`/countries/update/${params.row._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="viewButton">Edit</div>
                </Link>
              ) : (
                <div></div>
              )}

              {title === "Contact Information" ? (
                <Link
                  to={`/contactInfo/update/${params.row._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="viewButton">Edit</div>
                </Link>
              ) : (
                <div></div>
              )}

              {title === "Reviews" ? (
                <Link
                  to={`/review/reply/${params.row._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="viewButton">Reply</div>
                </Link>
              ) : (
                <div></div>

              )}
              {title === "News Letter" ? (
                <Link
                  to={`/newsLetter/update/${params.row._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="viewButton">Edit</div>
                </Link>
              ) : (
                <div></div>

              )}
              {/* {title === "Cutomise Trips Requests" ? (
                <Link
                  to={`/cutomiseTrips/view/${params.row._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="viewButton">View</div>
                </Link>
              ) : (
                <div></div>

              )} */}
            </div>

            {title === "Countries" || title === "News Letter" || (title === 'Users' && params.row.email === 'admin@mea-travel.com') ?
              ''
              :
              <div
                className="deleteButton"
                onClick={() => handleDelete(params.row._id)}
              >
                Delete
              </div>
            }
          </div>
        );
      },
    },
  ];

  const handleDownloadExcelSheet = (e) => {
    e.preventDefault()
    if (title === 'Bookings') {
      axios.get(`${BASE_URL}/booking/downloadExcelSheet`, { responseType: 'blob' })
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'bookings.xlsx');
          alert('File Downloaded Successfully')
        })
        .catch(err => { alert(err); console.log(err) })
    }
    else if (title === 'Cutomise Trips Requests')
      axios.get(`${BASE_URL}/cutomiseTrips/downloadExcelSheet`, { responseType: 'blob' })
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'TripRequests.xlsx');
          alert('File Downloaded Successfully')
        })
        .catch(err => { alert(err); console.log(err) })
  }
  return (
    <div className="datatable">
      <div className="datatableTitle">
        {title}
        {
          title === "Users" || title === "Tours" || title === 'Contact Information'
            ?
            <Link to={`/${path}/new`} className="link">Add New</Link>
            :
            title === 'Bookings' || title === 'Cutomise Trips Requests' ?
              <button className="link" onClick={handleDownloadExcelSheet}>Download Excel Sheet</button>
              : <div></div>
        }

      </div>
      <DataGrid
        className="datagrid"
        rows={list}
        columns={columns.concat(actionColumn)}
        pageSize={title === "News Letter" ? 1 : 9}
        rowsPerPageOptions={title === "News Letter" ? [1] : [9]}
        checkboxSelection
        getRowId={(row) => row._id}
      />
    </div>
  );
};

export default Datatable;
