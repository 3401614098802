// import "./UpdateCountry.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, CLOUDINARY_URL } from "../../utils/config";
import { useNavigate, useParams } from "react-router-dom";

const UpdateCountry = ({ inputs, title }) => {
    const navigate = useNavigate();
    const params = useParams();
    const [country, setcountry] = useState({
        country: '',
        image: '',
        imgTitle: '',
        imgDesc: '',
        overview_pragraph1: '',
        overview_pragraph2: '',
    });
    const [countryFormError, setcountryFormError] = useState({
        country: null,
        image: null,
        imgTitle: null,
        imgDesc: null,
        overview_pragraph1: null,
        overview_pragraph2: null,
    });
    const [files, setFiles] = useState(['', '', ''])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/countries/id/${params.id}`);
                const res = response.data;
                console.log('res', res)
                setcountry({
                    country: res.country,
                    image: res.image,
                    imgTitle: res.imgTitle,
                    imgDesc: res.imgDesc,
                    overview_pragraph1: res.overview_pragraph1,
                    overview_pragraph2: res.overview_pragraph2
                });
                setFiles(res.images.map(item => item))
            } catch (error) {
                console.error('Error fetching country info:', error);
            }
        };
        fetchData();
    }, [params.id]);

    const handleChange = (e, index) => {
        const { name, value, type } = e.target;

        if (type === 'file') {
            const newFiles = [...files];
            newFiles[index] = e.target.files[0];
            setFiles(newFiles);

            // Update the image source
            const reader = new FileReader();
            reader.onload = () => {
                const imageDataUrl = reader.result;
                const imgElement = document.getElementById(`image-${index}`);
                if (imgElement) {
                    imgElement.src = imageDataUrl;
                }
            };
            reader.readAsDataURL(e.target.files[0]);
        } else if (value === '') {
            setcountryFormError({
                ...countryFormError,
                [name]: 'This field is required'
            });
        } else {
            setcountry({
                ...country,
                [name]: value
            });
            setcountryFormError({
                ...countryFormError,
                [name]: null
            });
        }
    };

    const handleClick = async (e) => {
        e.preventDefault();
        const hasEmptyFields = Object.values(country).some(value => String(value).trim() === '');
        if (hasEmptyFields) {
            setcountryFormError({
                country: String(country.country).trim() === '' ? 'country is required' : null,
                image: String(country.image).trim() === '' ? 'country image is required' : null,
                imgTitle: String(country.imgTitle).trim() === '' ? 'country image Title is required' : null,
                imgDesc: String(country.imgDesc).trim() === '' ? 'country image Description is required' : null,
                overview_pragraph1: String(country.overview_pragraph1).trim() === '' ? 'overview pragraph1 is required' : null,
                overview_pragraph2: String(country.overview_pragraph2).trim() === '' ? 'overview pragraph2 is required' : null
            });
            return;
        }
        try {
            const formData = new FormData();
            const images = await Promise.all(
                Object.values(files).map(async (file) => {
                    const data = new FormData();
                    data.append("file", file);
                    data.append("upload_preset", "upload")
                    try {
                        const uploadRes = await axios.post(CLOUDINARY_URL, data);
                        const { url } = uploadRes.data;
                        return url;
                    } catch (error) {
                        console.error('Error uploading image to Cloudinary:', error);
                    }
                }));

            formData.append("upload_preset", "upload");

            // const uploadRes = await axios.post(CLOUDINARY_URL, formData);
            // const imageUrl = uploadRes.data.url;
            // const updatedImages = files.map((item, index) => {
            //         return images[index] || item.images[index]
            // });

            const updatedCountry = { ...country, images };
            await axios.put(`${BASE_URL}/countries/${params.id}`, updatedCountry);

            // await axios.put(`${BASE_URL}/countries/${params.id}`, country);
            alert("Country Updated Successfully");
            navigate('/countries');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="top">
                    <h1>{title}</h1>
                </div>
                <div className="bottom">
                    <div className="right">
                        <form>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginBottom: '2rem', width: '100%' }}>
                                {files && files.map((item, index) => {
                                    return (
                                        <div key={index} style={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem', width: '100%' }}>
                                            <label>Slider Image {index + 1}</label>
                                            <img
                                                src={files[index]}
                                                className="landingImage"
                                                id={`image-${index}`}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    borderRadius: '50%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                            <input
                                                type="file"
                                                id={`file-${index}`}
                                                onChange={(e) => handleChange(e, index)}
                                                style={{ border: 'none' }}
                                            />
                                        </div>
                                    );
                                })}
                                </div>
                            {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label htmlFor={input.id}>{input.label}</label>
                                    <input
                                        id={input.id}
                                        name={input.id}
                                        type={input.type}
                                        placeholder={input.placeholder}
                                        value={country[input.id]}
                                        onChange={handleChange}
                                        disabled={input.id === 'country'}
                                    />
                                    {countryFormError[input.id] && <div className="errorMessage">{countryFormError[input.id]}</div>}
                                </div>
                            ))}
                            <button
                                type="submit"
                                onClick={handleClick}
                            >
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateCountry;
