import "./reply.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { useNavigate, useParams } from "react-router-dom";

const Reply = ({ inputs, title }) => {

    const [review, setReview] = useState('')
    const [reviewOwner, setReviewOwner] = useState('')
    const [reply, setReply] = useState('')
    const [replyFormError, setReplyFormError] = useState(null)
    const params = useParams()
    const reviewID = params.id
    const navigate = useNavigate()

    useEffect(() => {
        const fetchReply = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/review/${reviewID}`);
                setReply(response.data.data.reply);
                console.log(response.data.data.reply);
            } catch (error) {
                console.error('Error fetching reply:', error);
            }
        };
        fetchReply();
    }, []);

    useEffect(() => {
        const fetchReview = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/review/${reviewID}`);
                setReview(response.data.data.reviewText);
                setReviewOwner(response.data.data.username)
            } catch (error) {
                console.error('Error fetching review:', error);
            }
        };
        fetchReview();
    }, [reviewID, review, reply]);

    const handleChange = (e) => {
        let value = e.target.value
        if (value === '') {
            setReplyFormError('This field is required')
        }
        else {
            setReply(value);
            setReplyFormError(null)
        }
    };

    const handleClick = async (e) => {
        e.preventDefault();

        const hasEmptyFields = reply.trim() === '';
        if (hasEmptyFields) {
            setReplyFormError('This field is required');
            return;
        } else {
            setReplyFormError(null)
            try {
                await axios.put(`${BASE_URL}/review/${reviewID}`, { reply })
                alert("Tour Created Successfully");
                navigate('/review')
            }
            catch (error) {
                console.log(error)
            }
        }
    };
    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="top">
                    <h1>{title}</h1>
                </div>
                <div className="bottom">
                    <div className="right">
                        <form>
                            <div className="formInput">
                                <label>Review Owner</label>
                                <input
                                    type="text"
                                    id="reviewOwner"
                                    value={reviewOwner}
                                    disabled
                                    style={{
                                        backgroundColor: 'inherit',
                                        color: 'grey',
                                        boxSizing: 'border-box',
                                        width: '100%',
                                    }}
                                />
                            </div>
                            <div className="formInput">
                                <label>Review</label>
                                <input
                                    type="text"
                                    id="review"
                                    value={review}
                                    disabled
                                    style={{
                                        backgroundColor: 'inherit',
                                        color: 'grey',
                                        boxSizing: 'border-box',
                                        width: '100%'
                                    }}
                                />
                            </div>
                            {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label>{input.label}</label>
                                    <input
                                        id={input.id}
                                        onChange={handleChange}
                                        type={input.type}
                                        placeholder={input.placeholder}
                                        value={reply}
                                    />
                                    {/* {replyFormError && <div className="errorMessage">{replyFormError}</div>} */}
                                </div>

                            ))}
                            <button
                                onClick={handleClick}
                            >Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reply;
