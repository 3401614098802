import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { 
  productInputs, 
  tourInputs, 
  userInputs, 
  replyInputs, 
  ContactInfoInputs, 
  CountriesInputs, 
  LandingHomeInputs,
  HeroHomeInputs,
  ExperienceHomeInputs,
  ExperienceNumbersHomeInputs,
  NewsLetterInputs
} from "./formSource";
import "./style/dark.scss";
import { useContext, useEffect } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import {
  userColumns,
  tourColumns,
  bookingColumns,
  reviewsColumns,
  contactInfoColumns,
  countriesColumns,
  newsLetterColumns,
  newsLetterEmailsColumns,
  customiseTripColumns
} from "./datatablesource";
import NewTour from "./pages/newTour/NewTour";
import UpdateTour from "./pages/updateTour/UpdateTour";
import NewUser from "./pages/newUser/NewUser";
import UpdateUser from "./pages/updateUser/UpdateUser";
import Reply from "./pages/replyOnReview/reply";
import UpdateContactInfo from "./pages/updateContactInfo/updateContactInfo";
import NewContactInfo from "./pages/newContactInfo/New";
import UpdateCountry from "./pages/updateCountries/updateCountries";
import HomeInfo from "./pages/HomeInfo/HomeInfo";
import UpdateHome from "./pages/HomeInfo/updateHome";
import NewsLetter from "./pages/newsLetter/NewsLetterUpdate";
import Gallery from "./pages/gallery/Gallery";
import ViewCustomiseTrip from "./pages/customiseTrip/customiseTrip";

function App() {
  useEffect(() => {
    document.title = "MEA-Travel Admin Panel";
  }, []);

  const { darkMode } = useContext(DarkModeContext);

  const ProtectedRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (!user) {
      return <Navigate to="/login" />;
    }

    return children;
  };
  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route
              index
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />

            <Route path="users">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <List columns={userColumns} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="update/:id"
                element={
                  <ProtectedRoute>
                    <UpdateUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                    <NewUser inputs={userInputs} title="Add New User" />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="tours">
              <Route index element={<List columns={tourColumns} />} />
              <Route
                path=":tourId"
                element={
                  <ProtectedRoute>
                    <Single />
                  </ProtectedRoute>
                }
              />
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                    <NewTour />
                  </ProtectedRoute>
                }
              />
              <Route
                path="update/:id"
                element={
                  <ProtectedRoute>
                    <UpdateTour />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="booking">
              <Route index element={<List columns={bookingColumns} />} />
              <Route
                path=":bookingId"
                element={
                  <ProtectedRoute>
                    <Single />
                  </ProtectedRoute>
                }
              />
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                    <New inputs={productInputs} title="Add New Booking" />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="review">
              <Route index element={<List columns={reviewsColumns} />} />
              <Route
                path=":reviewId"
                element={
                  <ProtectedRoute>
                    <Single />
                  </ProtectedRoute>
                }
              />
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                    <New inputs={productInputs} title="Add New Review" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="reply/:id"
                element={
                  <ProtectedRoute>
                    <Reply inputs={replyInputs} title="Reply On A Review" />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="contactInfo">
              <Route index element={<List columns={contactInfoColumns} />} />
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                    <NewContactInfo inputs={ContactInfoInputs} title="Add New Contact Information" />
                  </ProtectedRoute>
                }
              />
              <Route path="update/:id" element={<UpdateContactInfo inputs={ContactInfoInputs} title="Update Contact Information" />} />
            </Route>
            <Route path="countries">
              <Route index element={<List columns={countriesColumns} />} />
              <Route path="update/:id" element={<UpdateCountry inputs={CountriesInputs} title="Update Country Information" />} />
            </Route>
            <Route path="home">
              <Route index element={<HomeInfo />} />
              <Route path="update/:id" element={<UpdateHome LandingHomeInputs={LandingHomeInputs} HeroHomeInputs={HeroHomeInputs} ExperienceHomeInputs={ExperienceHomeInputs} ExperienceNumbersHomeInputs={ExperienceNumbersHomeInputs} />} />
            </Route>
            <Route path="newsLetter">
              <Route index element={<List columns={newsLetterColumns} />} />
              <Route path="update/:id" element={<NewsLetter inputs={NewsLetterInputs} />} />
            </Route>
            <Route path="newsLetterEmails">
              <Route index element={<List columns={newsLetterEmailsColumns} />} />
            </Route>
            <Route path="gallery">
              <Route index element={<Gallery />} />
            </Route>
            <Route path="cutomiseTrips">
              <Route index element={<List columns={customiseTripColumns} />} />
              {/* <Route path="view/:id" element={<ViewCustomiseTrip />} /> */}
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
