export const tourInputs = [
    {
        id: "title",
        label: "Title En",
        type: "text",
        placeholder: "Tour Title",
    },
    {
        id: "title_Ar",
        label: "Title Ar",
        type: "text",
        placeholder: "Tour Title",
    },
    {
        id: "title_Es",
        label: "Title Es",
        type: "text",
        placeholder: "Tour Title",
    },
    {
        id: "city_En",
        label: "City En",
        type: "text",
        placeholder: "City",
    },
    {
        id: "city_Ar",
        label: "City Ar",
        type: "text",
        placeholder: "City",
    },
    {
        id: "city_Es",
        label: "City Es",
        type: "text",
        placeholder: "City",
    },
    {
        id: "address_En",
        label: "Address En",
        type: "text",
        placeholder: "Address",
    },
    {
        id: "address_Ar",
        label: "Address Ar",
        type: "text",
        placeholder: "Address",
    },
    {
        id: "address_Es",
        label: "Address Es",
        type: "text",
        placeholder: "Address",
    },
    {
        id: "desc_En",
        label: "Description En",
        type: "text",
        placeholder: "Description",
    },
    {
        id: "desc_Ar",
        label: "Description Ar",
        type: "text",
        placeholder: "Description",
    },
    {
        id: "desc_Es",
        label: "Description Es",
        type: "text",
        placeholder: "Description",
    },
    {
        id: "price",
        label: "Price",
        type: "number",
        placeholder: "Price",
    },
    {
        id: "maxGroupSize",
        label: "Max Group Size",
        type: "number",
        placeholder: "Max Group Size",
    },
];



export const GlanceInputs = [
    {
        id: "glanceDay",
        label: "Glance Day",
        type: "number",
        placeholder: "Enter Glance Day",
    },
    {
        id: "glanceOrder",
        label: "Glance Order",
        type: "number",
        placeholder: "Enter Glance Order",
    },
    {
        id: "glanceTitle_En",
        label: "Glance Title En",
        type: "text",
        placeholder: "Enter Glance Title",
    },
    {
        id: "glanceTitle_Ar",
        label: "Glance Title Ar",
        type: "text",
        placeholder: "Enter Glance Title",
    },
    {
        id: "glanceTitle_Es",
        label: "Glance Title Es",
        type: "text",
        placeholder: "Enter Glance Title",
    },
    {
        id: "glanceItem_En",
        label: "Glance Item En",
        type: "text",
        placeholder: "Enter Glance Item",
    },
    {
        id: "glanceItem_Ar",
        label: "Glance Item Ar",
        type: "text",
        placeholder: "Enter Glance Item",
    },
    {
        id: "glanceItem_Es",
        label: "Glance Item Es",
        type: "text",
        placeholder: "Enter Glance Item",
    },
];




export const ItrInputs = [
    {
        id: "itrday",
        label: "Glance Day",
        type: "number",
        placeholder: "Enter Glance Day",
    },
    {
        id: "itrtitle_En",
        label: "Itinerary Title En",
        type: "text",
        placeholder: "Enter Itinerary Title",
    },
    {
        id: "itrtitle_Ar",
        label: "Itinerary Title Ar",
        type: "text",
        placeholder: "Enter Itinerary Title",
    },
    {
        id: "itrtitle_Es",
        label: "Itinerary Title Es",
        type: "text",
        placeholder: "Enter Itinerary Title",
    },
];


export const ItrTextarea = [
    {
        id: "itrdesc_En",
        label: "Description En",
        placeholder: "Enter Description",
        type: "textarea",
    },
    {
        id: "itrdesc_Ar",
        label: "Description Ar",
        placeholder: "Enter Description",
        type: "textarea",
    },    {
        id: "itrdesc_Es",
        label: "Description Es",
        placeholder: "Enter Description",
        type: "textarea",
    },
];


export const AccommodationInputs = [
    {
        id: "accommodationTitle_En",
        label: "Accommodation Title En",
        placeholder: "Enter Accommodation Title",
        type: "text",
    },
    {
        id: "accommodationTitle_Ar",
        label: "Accommodation Title Ar",
        placeholder: "Enter Accommodation Title",
        type: "text",
    },
    {
        id: "accommodationTitle_Es",
        label: "Accommodation Title Es",
        placeholder: "Enter Accommodation Title",
        type: "text",
    },
];

export const AccommodationTextarea = [
    {
        id: "accommodationDesc_En",
        label: "Accommodation Description En",
        placeholder: "Enter Accommodation Description",
        type: "textarea",
    },
    {
        id: "accommodationDesc_Ar",
        label: "Accommodation Description Ar",
        placeholder: "Enter Accommodation Description",
        type: "textarea",
    },
    {
        id: "accommodationDesc_Es",
        label: "Accommodation Description Es",
        placeholder: "Enter Accommodation Description",
        type: "textarea",
    },
];

export const NoteTextarea = [
    {
        id: "noteDesc_En",
        label: "Note En",
        placeholder: "Enter Note",
        type: "textarea",
    },
    {
        id: "noteDesc_Ar",
        label: "Note Ar",
        placeholder: "Enter Note",
        type: "textarea",
    },
    {
        id: "noteDesc_Es",
        label: "Note Es",
        placeholder: "Enter Note",
        type: "textarea",
    },
];

export const Inclusiontarea = [
    {
        id: "inclusionDesc_En",
        placeholder: "Enter An Inclusion in En",
        label: 'Inclusion in En',
        type: "textarea",
    },
    {
        id: "inclusionDesc_Ar",
        placeholder: "Enter An Inclusion in Ar",
        label: 'Inclusion in Ar',
        type: "textarea",
    },
    {
        id: "inclusionDesc_Es",
        placeholder: "Enter An Inclusion in Es",
        label: 'Inclusion in Es',
        type: "textarea",
    },
];