import avatar from "./assets/images/user.png";

export const userColumns = [
  { field: "_id", headerName: "ID", width: 260 },
  {
    field: "user",
    headerName: "User",
    width: 190,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.photo || avatar} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
  },

  {
    field: "role",
    headerName: "Role",
    width: 110,
  },
  {
    field: "accountType",
    headerName: "Account Type",
    width: 110,
  },
  {
    field: "travelAgent_Verification",
    headerName: "Travel Agent Verification",
    width: 190,
    renderCell: (params) => {
      return (
        <center>
          {
            params.row.accountType === 'TravelAgent' ?
            (params.row.travelAgent_Verification ? "Verified" : "Not Verified") 
              :
              '-'
          }
        </center>
      );
    },
  },
  {
    field: "is_email_verified",
    headerName: "Individual Verification",
    width: 190,
    renderCell: (params) => {
      return (
        <center>
          {
            params.row.accountType !== 'TravelAgent' ?
            (params.row.is_email_verified ? "Verified" : "Not Verified") 
              :
              '-'
          }
        </center>
      );
    },
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.status}`}>
  //         {params.row.status}
  //       </div>
  //     );
  //   },
  // },
];

export const tourColumns = [
  { field: "_id", headerName: "ID", width: 280 },
  {
    field: "title",
    headerName: "Title",
    width: 250,
  },
  {
    field: "price",
    headerName: "Price",
    width: 300,
  },

  {
    field: "maxGroupSize",
    headerName: "Max Group Size",
    width: 150,
  },
];

export const bookingColumns = [
  { field: "_id", headerName: "ID", width: 240 },
  {
    field: "tourName",
    headerName: "Tour Name",
    width: 200,
  },
  {
    field: "userEmail",
    headerName: "User Email",
    width: 200,
  },
  {
    field: "fullName",
    headerName: "Full Name",
    width: 100,
  },
  {
    field: "nationality",
    headerName: "Nationality",
    width: 150,
  },

  {
    field: "guestSize",
    headerName: "Guest Size",
    width: 150,
  },

  {
    field: "phone",
    headerName: "Phone",
    width: 150,
  },
];

export const reviewsColumns = [
  { field: "_id", headerName: "ID", width: 280 },
  {
    field: "username",
    headerName: "Username",
    width: 250,
  },
  {
    field: "reviewText",
    headerName: "Review Text",
    width: 300,
  },

  {
    field: "rating",
    headerName: "Rating",
    width: 150,
  },

];

export const contactInfoColumns = [
  {
    field: "phone",
    headerName: "Phone Number",
    width: 330,
  },
  {
    field: "email",
    headerName: "Email",
    width: 330,
  },
  {
    field: "showInHomePage",
    headerName: "Show In Home Page",
    width: 320,
  },
];

export const countriesColumns = [
  {
    field: "image",
    headerName: "Image",
    width: 180,
    renderCell: (params) => (
      <img src={params.value} alt="Countries" style={{ width: '100%', height: 'auto' }} />
    ),
  },
  {
    field: "country",
    headerName: "Country",
    width: 190,
  },
  {
    field: "imgTitle",
    headerName: "Image Title",
    width: 200,
  },
  {
    field: "imgDesc",
    headerName: "Description",
    width: 200,
  },
  {
    field: "overview_pragraph1",
    headerName: "overview_pragraph1",
    width: 200,
  },
  {
    field: "overview_pragraph2",
    headerName: "overview_pragraph1",
    width: 200,
  },
];

export const newsLetterColumns = [
  {
    field: "title",
    headerName: "Title",
    width: 580,
  },
  {
    field: "desc",
    headerName: "Description",
    width: 580,
  },
];
export const newsLetterEmailsColumns = [
  {
    field: "email",
    headerName: "Email",
    width: 700,
  },
];

export const customiseTripColumns = [
  {
    field: "state",
    headerName: "State",
    width: 100,
  },
  {
    field: "email",
    headerName: "User Email",
    width: 200,
  },
  {
    field: "destination",
    headerName: "Destination",
    width: 100,
  },
  {
    field: "date",
    headerName: "Date",
    width: 120,
  },
  {
    field: "flexible",
    headerName: "Flexible",
    width: 100,
  },
  {
    field: "tripLength",
    headerName: "Trip Length",
    width: 100,
  },
  {
    field: "budget",
    headerName: "Budget",
    width: 100,
  },
  {
    field: "includeInternationalFlights",
    headerName: "Include International Flights",
    width: 210,
  },
];


//temporary data
export const userRows = [
  {
    id: 1,
    username: "Snow",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: "active",
    email: "1snow@gmail.com",
    age: 35,
  },
  {
    id: 2,
    username: "Jamie Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "2snow@gmail.com",
    status: "passive",
    age: 42,
  },
  {
    id: 3,
    username: "Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "3snow@gmail.com",
    status: "pending",
    age: 45,
  },
  {
    id: 4,
    username: "Stark",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "4snow@gmail.com",
    status: "active",
    age: 16,
  },
  {
    id: 5,
    username: "Targaryen",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "5snow@gmail.com",
    status: "passive",
    age: 22,
  },
  {
    id: 6,
    username: "Melisandre",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "6snow@gmail.com",
    status: "active",
    age: 15,
  },
  {
    id: 7,
    username: "Clifford",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "7snow@gmail.com",
    status: "passive",
    age: 44,
  },
  {
    id: 8,
    username: "Frances",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "8snow@gmail.com",
    status: "active",
    age: 36,
  },
  {
    id: 9,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "pending",
    age: 65,
  },
  {
    id: 10,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    age: 65,
  },
];
