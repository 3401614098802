import "./updateUser.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import axios from "axios";
import { BASE_URL, CLOUDINARY_URL } from "../../utils/config";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const UpdateUser = () => {
  const [file, setFile] = useState("");
  const [info, setInfo] = useState({});

  const [values, setValues] = useState({
    username: "",
    email: "",
    password: "",
    photo: "",
    role: "",
    is_email_verified: "",
    email_verification_code: "",
    accountType: '',
    travelAgent_Verification: ''
  });

  const { id } = useParams();

  useEffect(() => {
    axios.get(`${BASE_URL}/users/${id}`)
      .then(res => {
        setValues({
          ...values, username: res.data.data.username,
          email: res.data.data.email,
          password: res.data.data.password,
          photo: res.data.data.photo,
          role: res.data.data.role,
          is_email_verified: res.data.data.is_email_verified,
          email_verification_code: res.data.data.email_verification_code,
          accountType: res.data.data.accountType,
          travelAgent_Verification: res.data.data.travelAgent_Verification
        });
        setFile(res.data.data.photo)
        console.log(res.data)
      })
      .catch(err => console.log(err));
  }, [])

  const handleChange = (e) => {
    const { type } = e.target;

    if (type === 'file') {
      const newFile = e.target.files[0];
      setFile(newFile);

      // Update the image source
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result;
        const imgElement = document.getElementById('image');
        if (imgElement) {
          imgElement.src = imageDataUrl;
        }
      };
      reader.readAsDataURL(newFile);
    } else setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const navigate = useNavigate();

  const handleClick = async (e) => {
    e.preventDefault();

    let updatedValues
    if (file != '') {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "upload");

      const uploadRes = await axios.post(CLOUDINARY_URL, formData);
      const imageUrl = uploadRes.data.url;
      updatedValues = { ...values, photo: imageUrl };
    }
    else updatedValues = values


    axios.put(`${BASE_URL}/users/${id}`, updatedValues)
      .then(res => {
        navigate('/users')
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Edit User</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form>
              <div className="formInput">
                <label>Image</label>
                <img
                  src={file || 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'}
                  className="image"
                  id={`image`}
                  style={{ width: '10rem', height: ' 10rem', borderRadius: '50%' }}
                />
                <input
                  id='file'
                  type='file'
                  onChange={handleChange}
                />
              </div>

              <div className="formInput">

              </div>

              <div className="formInput">
                <label>Username</label>
                <input
                  id="username"
                  value={values.username}
                  onChange={e => setValues({ ...values, username: e.target.value })}
                  type="text"
                  placeholder="Enter Username"
                />
              </div>

              <div className="formInput">
                <label>Email</label>
                <input
                  id="email"
                  value={values.email}
                  onChange={e => setValues({ ...values, email: e.target.value })}
                  type="text"
                  placeholder="Enter Email"
                />
              </div>

              <div className="formInput">
                <label>Password</label>
                <input
                  id="password"
                  value={values.password}
                  type="password"
                  placeholder="Enter Password"
                  disabled
                />
              </div>

              {values.accountType === 'Individual' && <>
                <div className="formInput">
                  <label>Is Email Verified?</label>
                  <select
                    id="is_email_verified"
                    onChange={e => setValues({ ...values, is_email_verified: e.target.value })}
                    style={{ width: "100%" }}
                    value={values.is_email_verified}
                  >
                    <option value={"true"}>True</option>
                    <option value={"false"}>False</option>
                  </select>
                </div>

                <div className="formInput">
                  <label>Email Verification Code</label>
                  <input
                    id="email_verification_code"
                    value={values.email_verification_code}
                    type="text"
                    disabled
                  />
                </div>
              </>}

              <div className="formInput">
                <label>Role</label>
                <select
                  id="role"
                  onChange={e => setValues({ ...values, role: e.target.value })}
                  style={{ width: "100%" }}
                  value={values.role}
                >
                  <option value={"user"}>User</option>
                  <option value={"moderator"}>Moderator</option>
                  <option value={"admin"}>Admin</option>
                </select>
              </div>
              <div className="formInput">
                <label>Account Type</label>
                <select
                  id="accountType"
                  onChange={e => setValues({ ...values, accountType: e.target.value })}
                  value={values.accountType}
                  style={{ width: "100%" }}
                >
                  <option value={"Individual"}>Individual</option>
                  <option value={"TravelAgent"}>Travel Agent</option>
                </select>
              </div>
              {
                values.accountType === 'TravelAgent' &&
                <div className="formInput">
                  <label>Travel Agent Verification</label>
                  <select
                    id="travelAgent_Verification"
                    onChange={e => setValues({ ...values, travelAgent_Verification: e.target.value })}
                    value={values.travelAgent_Verification}
                    style={{ width: "100%" }}
                  >
                    <option value={true}>Verified</option>
                    <option value={false}>Not Verified</option>
                  </select>
                </div>
              }
              <div className="formInput">

              </div>
              <button onClick={handleClick}>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
