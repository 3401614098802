import "./updateContactInfo.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/config";
import { useNavigate, useParams } from "react-router-dom";

const UpdateContactInfo = ({ inputs, title }) => {
    const navigate = useNavigate();
    const params = useParams();
    const [contactInfo, setContactInfo] = useState({
        phone: '',
        email: '',
        showInHomePage: false
    });
    const [contactInfoFormError, setContactInfoFormError] = useState({
        phone: null,
        email: null
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/contactInfo/${params.id}`);
                const res = response.data;
                console.log(response)
                setContactInfo({
                    phone: res.phone,
                    email: res.email,
                    showInHomePage: res.showInHomePage
                });
            } catch (error) {
                console.error('Error fetching contact info:', error);
            }
        };
        fetchData();
    }, [params.id]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;


        if (value === '') {
            setContactInfoFormError({
                ...contactInfoFormError,
                [name]: 'This field is required'
            });
        } else {
            if (type === 'checkbox') {
                setContactInfo(prevInclusions => ({
                    ...prevInclusions,
                    [name]: checked
                }));
            } else {
                setContactInfo({
                    ...contactInfo,
                    [name]: value
                });
                setContactInfoFormError({
                    ...contactInfoFormError,
                    [name]: null
                });
            }

        }
    };

    const handleClick = async (e) => {
        e.preventDefault();
        const hasEmptyFields = Object.values(contactInfo).some(value => String(value).trim() === '');
        if (hasEmptyFields) {
            setContactInfoFormError({
                phone: String(contactInfo.phone).trim() === '' ? 'Phone is required' : null,
                email: String(contactInfo.email).trim() === '' ? 'Email is required' : null
            });
            return;
        }
        try {
            await axios.put(`${BASE_URL}/contactInfo/${params.id}`, contactInfo);
            alert("Contact info Updated Successfully");
            navigate('/contactInfo');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />
                <div className="top">
                    <h1>{title}</h1>
                </div>
                <div className="bottom">
                    <div className="right">
                        <form>
                            {/* {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label htmlFor={input.id}>{input.label}</label>
                                    <input
                                        id={input.id}
                                        name={input.id}
                                        type={input.type}
                                        placeholder={input.placeholder}
                                        value={contactInfo[input.id]}
                                        onChange={handleChange}
                                    />
                                    {contactInfoFormError[input.id] && <div className="errorMessage">{contactInfoFormError[input.id]}</div>}
                                </div>
                            ))} */}
                            {inputs.map((input) => (
                                <div className="formInput" key={input.id}>
                                    <label htmlFor={input.id}>{input.label}</label>
                                    {input.type === 'checkbox' ? (
                                        <input
                                            id={input.id}
                                            name={input.id}
                                            type={input.type}
                                            checked={contactInfo[input.id]}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <input
                                            id={input.id}
                                            name={input.id}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            value={contactInfo[input.id]}
                                            onChange={handleChange}
                                        />
                                    )}
                                    {contactInfoFormError[input.id] && <div className="errorMessage">{contactInfoFormError[input.id]}</div>}
                                </div>
                            ))}
                            <button
                                type="submit"
                                onClick={handleClick}
                            >
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateContactInfo;
