import "./newUser.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import axios from "axios";
import { BASE_URL, CLOUDINARY_URL } from "../../utils/config";
import { useNavigate } from "react-router-dom";

const NewUser = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [info, setInfo] = useState({});
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { type } = e.target;

    if (type === 'file') {
      const newFile = e.target.files[0];
      setFile(newFile);

      // Update the image source
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result;
        const imgElement = document.getElementById('image');
        if (imgElement) {
          imgElement.src = imageDataUrl;
        }
      };
      reader.readAsDataURL(newFile);
    } else
      setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();

    let formData
    if (file != ''){
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "upload");
  
      const uploadRes = await axios.post(CLOUDINARY_URL, formData);
      const imageUrl = uploadRes.data.url;
      formData = { ...info, photo: imageUrl };
    }
    else formData = info

    await axios.post(`${BASE_URL}/auth/register`, formData)
      .then(res => {
        navigate('/users')
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form>
              <div className="formInput">
                <label>Image</label>
                <img
                  src={file || 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'}
                  className="image"
                  id={`image`}
                  style={{ width: '10rem', height: ' 10rem', borderRadius: '50%' }}
                />
                <input
                  id='file'
                  type='file'
                  onChange={handleChange}
                />
              </div>

              <div className="formInput">
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    onChange={handleChange}
                    type={input.type}
                    placeholder={input.placeholder}
                  />
                </div>
              ))}

              <div className="formInput">
                <label>Role</label>
                <select
                  id="role"
                  onChange={handleChange}
                  style={{ width: "100%" }}
                >
                  <option value={"user"}>User</option>
                  <option value={"moderator"}>Moderator</option>
                  <option value={"admin"}>Admin</option>
                </select>
              </div>
              <div className="formInput">
                <label>Account Type</label>
                <select
                  id="accountType"
                  onChange={handleChange}
                  style={{ width: "100%" }}
                >
                  <option value={"Individual"}>Individual</option>
                  <option value={"TravelAgent"}>Travel Agent</option>
                </select>
              </div>
              <button onClick={handleClick}>Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
