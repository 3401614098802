import "./updateTour.scss";
import * as React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState } from "react";
import axios from "axios";
import { BASE_URL, CLOUDINARY_URL } from "../../utils/config";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccommodationInputs, AccommodationTextarea, GlanceInputs, Inclusiontarea, ItrInputs, ItrTextarea, NoteTextarea, tourInputs } from "../../TourFormInputs"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UpdateTour = () => {
  const [files, setFiles] = useState(['', '', '']);
  const [mapFile, setmapFile] = useState('');

  // ----------------------------inputs
  const [value, setValue] = React.useState(0);

  const [values, setValues] = useState({
    title: '', title_Ar: '', title_Es: '',
    city_En: '', city_Ar: '', city_Es: '',
    address_En: '', address_Ar: '', address_Es: '',
    desc_En: '', desc_Ar: '', desc_Es: '',
    price: 1, maxGroupSize: 1, featured: '', country: ''
  });

  const [valuesFormError, setValuesFormError] = useState({
    title: null, title_Ar: null, title_Es: null,
    city_En: null, city_Ar: null, city_Es: null,
    address_En: null, address_Ar: null, address_Es: null,
    desc_En: null, desc_Ar: null, desc_Es: null,
  });

  const [highlights, setHighlights] = useState({
    highlights_En: '', highlights_Ar: '', highlights_Es: ''
  });
  const [highlights_FormError, setHighlights_FormError] = useState({
    highlights_En: null, highlights_Ar: null, highlights_Es: null
  });

  const [glances, setGlances] = useState({
    glanceDay: "", glanceOrder: "",
    glanceTitle_En: "", glanceTitle_Ar: "", glanceTitle_Es: "",
    glanceItem_En: "", glanceItem_Ar: "", glanceItem_Es: "",
  })
  const [itinerary, setItinerary] = useState({
    itrday: "",
    itrtitle_En: "", itrtitle_Ar: "", itrtitle_Es: "",
    itrdesc_En: "", itrdesc_Ar: "", itrdesc_Es: "",
  })
  const [accommodations, setAccommodations] = useState({
    accommodationTitle_En: "", accommodationTitle_Ar: "", accommodationTitle_Es: "",
    accommodationDesc_En: "", accommodationDesc_Ar: "", accommodationDesc_Es: "",
  })
  const [note, setNote] = useState({
    noteDesc_En: "", noteDesc_Ar: "", noteDesc_Es: "",
  })
  const [inclusions, setInclusions] = useState({
    inclusionIncluded: true,
    inclusionDesc_En: "", inclusionDesc_Ar: "", inclusionDesc_Es: "",
  })

  // ----------------------------inputs ERRORS 
  const [glancesFormError, setGlancesFormError] = useState({
    glanceDay: null, glanceOrder: null,
    glanceTitle_En: null, glanceTitle_Ar: null, glanceTitle_Es: null,
    glanceItem_En: null, glanceItem_Ar: null, glanceItem_Es: null,
  })

  const [itineraryFormError, setItineraryFormError] = useState({
    itrday: null,
    itrtitle_En: null, itrtitle_Ar: null, itrtitle_Es: null,
    itrdesc_En: null, itrdesc_Ar: null, itrdesc_Es: null,
  })

  const [accommodationsFormError, setAccommodationsFormError] = useState({
    accommodationTitle_En: null, accommodationTitle_Ar: null, accommodationTitle_Es: null,
    accommodationDesc_En: null, accommodationDesc_Ar: null, accommodationDesc_Es: null,
  })
  const [noteFormError, setNoteFormError] = useState({
    noteDesc_En: null, noteDesc_Ar: null, noteDesc_Es: null,
  })
  const [inclusionsFormError, setInclusionsFormError] = useState({
    inclusionIncluded: true,
    inclusionDesc_En: null, inclusionDesc_Ar: null, inclusionDesc_Es: null,
  })

  // ----------------------------inputs ARRAYS 
  const [glanceArr, setglanceArr] = useState([])
  const [itrArr, setItrArr] = useState([])
  const [accommodationsArr, setAccommodationsArr] = useState([])
  const [noteArr, setNoteArr] = useState([])
  const [inclusionsArr, setInclusionsArr] = useState([])
  const [highlightArr, setHighlightArr] = useState([])

  const { id } = useParams();

  useEffect(() => {
    axios.get(`${BASE_URL}/tours/${id}`)
      .then(res => {
        setValues({
          ...values,
          title: res.data.data.title,
          title_Ar: res.data.data.title_Ar,
          title_Es: res.data.data.title_Es,
          address_En: res.data.data.address_En,
          address_Ar: res.data.data.address_Ar,
          address_Es: res.data.data.address_Es,
          city_En: res.data.data.city_En,
          city_Ar: res.data.data.city_Ar,
          city_Es: res.data.data.city_Es,
          desc_En: res.data.data.desc_En,
          desc_Ar: res.data.data.desc_Ar,
          desc_Es: res.data.data.desc_Es,
          country: res.data.data.country,
          featured: res.data.data.featured,
          maxGroupSize: res.data.data.maxGroupSize,
          photos: res.data.data.photos,
          maplink: res.data.data.maplink,
          price: res.data.data.price,
          reviews: res.data.data.reviews,
        });

        setHighlightArr(res.data.data.highlights.map(item => ({
          highlights_En: item.highlights_En,
          highlights_Ar: item.highlights_Ar,
          highlights_Es: item.highlights_Es,
        })));

        setglanceArr(res.data.data.glances.map(glance => ({
          glanceDay: glance.glanceDay,
          glanceOrder: glance.glanceOrder,
          glanceTitle_En: glance.glanceTitle_En,
          glanceTitle_Ar: glance.glanceTitle_Ar,
          glanceTitle_Es: glance.glanceTitle_Es,
          glanceItem_En: glance.glanceItem_En,
          glanceItem_Ar: glance.glanceItem_Ar,
          glanceItem_Es: glance.glanceItem_Es,
        })));

        setItrArr(res.data.data.itinerary.map(itr => ({
          itrday: itr.itrday,
          itrtitle_En: itr.itrtitle_En,
          itrtitle_Ar: itr.itrtitle_Ar,
          itrtitle_Es: itr.itrtitle_Es,
          itrdesc_En: itr.itrdesc_En,
          itrdesc_Ar: itr.itrdesc_Ar,
          itrdesc_Es: itr.itrdesc_Es,
        })));

        setAccommodationsArr(res.data.data.accommodations.map(accommodations => ({
          accommodationTitle_En: accommodations.accommodationTitle_En,
          accommodationTitle_Ar: accommodations.accommodationTitle_Ar,
          accommodationTitle_Es: accommodations.accommodationTitle_Es,
          accommodationDesc_En: accommodations.accommodationDesc_En,
          accommodationDesc_Es: accommodations.accommodationDesc_Es,
          accommodationDesc_Ar: accommodations.accommodationDesc_Ar,
        })));

        setNoteArr(res.data.data.notes.map(note => ({
          noteDesc_En: note.noteDesc_En,
          noteDesc_Ar: note.noteDesc_Ar,
          noteDesc_Es: note.noteDesc_Es,
        })));

        setInclusionsArr(res.data.data.inclusions.map(inclusion => ({
          inclusionIncluded: inclusion.inclusionIncluded,
          inclusionDesc_En: inclusion.inclusionDesc_En,
          inclusionDesc_Ar: inclusion.inclusionDesc_Ar,
          inclusionDesc_Es: inclusion.inclusionDesc_Es,
        })));
        setFiles(res.data.data.photos)
        setmapFile(res.data.data.maplink)
      })
      .catch(err => console.log(err));
  }, [])
  console.log('default', values)

  // -----------------------------handle ON CHANGE
  const handleFileChange = (e, index) => {
    console.log(e.target.name)
    if(e.target.name != 'mapFile'){
      const newFiles = [...files];
      newFiles[index] = e.target.files[0];
      setFiles(newFiles);
  
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageSrc = event.target.result;
        const imgElement = document.getElementById(`img-${index}`);
        if (imgElement) {
          imgElement.src = imageSrc;
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else{
      const newFiles = e.target.files[0];
      setmapFile(newFiles);
  
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageSrc = event.target.result;
        const imgElement = document.getElementById("mapFile");
        if (imgElement) {
          imgElement.src = imageSrc;
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }

  };

  const handleChange = (e, newValue) => {
    console.log(e,newValue)
    setValue(newValue);
    setValues(prevValues => ({
      ...prevValues,
      [e.target.id]: e.target.value
    }));
  };

  function handleChangeHighlight(e) {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    setHighlights({ ...highlights, [name]: value })
    setHighlights_FormError({
      ...highlights_FormError,
      [name]: value.trim(" ").length === 0
        ? `This field is required`
        : null
    });
  }

  function handleChangeGlance(e) {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    setGlances({ ...glances, [name]: value })
    setGlancesFormError({
      ...glancesFormError,
      [name]: value.trim(" ").length === 0
        ? `This field is required`
        : null
    });
  }

  function handleChangeItr(e) {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    if (value == '') {
      setItineraryFormError({
        ...itineraryFormError,
        [name]: `This field is required`
      });
    }
    else {
      setItinerary({ ...itinerary, [name]: value })
      setItineraryFormError({
        ...itineraryFormError,
        [name]: null
      });
    }
  }

  function handleChangeAccommodations(e) {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    if (value == '') {
      setAccommodationsFormError({
        ...accommodationsFormError,
        [name]: `This field is required`
      });
    }
    else {
      setAccommodations({ ...accommodations, [name]: value })
      setAccommodationsFormError({
        ...accommodationsFormError,
        [name]: null
      });
    }
  }

  function handleChangeNote(e) {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    if (value == '') {
      setNoteFormError({
        ...noteFormError,
        [name]: `This field is required`
      });
    }
    else {
      setNote({ ...note, [name]: value })
      setNoteFormError({
        ...noteFormError,
        [name]: null
      });
    }
  }

  function handleChangeInclusions(e) {
    e.preventDefault();
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setInclusions(prevInclusions => ({
        ...prevInclusions,
        [name]: checked
      }));
    } else {
      if (value !== null && value.trim() === '') {
        setInclusionsFormError({
          ...inclusionsFormError,
          [name]: 'This field is required',
        });
      } else {
        setInclusions(prevInclusions => ({
          ...prevInclusions,
          [name]: value
        }));
        setInclusionsFormError({
          ...inclusionsFormError,
          [name]: null,
        });
      }
    }
  }

  // Setting Data
  let { highlights_En, highlights_Ar, highlights_Es } = highlights;
  let { glanceDay, glanceOrder, glanceTitle_En, glanceTitle_Ar, glanceTitle_Es, glanceItem_En, glanceItem_Ar, glanceItem_Es } = glances;
  let { itrday, itrtitle_En, itrtitle_Ar, itrtitle_Es, itrdesc_En, itrdesc_Ar, itrdesc_Es } = itinerary;
  let { accommodationTitle_En, accommodationTitle_Ar, accommodationTitle_Es, accommodationDesc_En, accommodationDesc_Ar, accommodationDesc_Es } = accommodations;
  let { noteDesc_En, noteDesc_Ar, noteDesc_Es } = note;
  let { inclusionIncluded, inclusionDesc_En, inclusionDesc_Ar, inclusionDesc_Es } = inclusions;

  const navigate = useNavigate();

  // ----------------------------handle ON CLICK submit
  async function handleClickHighlights(e) {
    e.preventDefault()
    const hasEmptyFields = Object.values(highlights).some(value => value.trim() === ''); // if user didn't change input values and remains empty

    if (hasEmptyFields) {
      setHighlights_FormError(prevState => ({
        ...prevState,
        highlights_En: highlights.highlights_En.trim() === '' ? 'This field is required' : null,
        highlights_Ar: highlights.highlights_Ar.trim() === '' ? 'This field is required' : null,
        highlights_Es: highlights.highlights_Es.trim() === '' ? 'This field is required' : null,
      }));
      return;
    } else {
      setHighlights_FormError({});
      setHighlightArr([...highlightArr, { highlights_En, highlights_Ar, highlights_Es }]);
      setHighlights({ highlights_En: '', highlights_Ar: '', highlights_Es: '' });
    }
  }

  async function handleClickGalance() {
    const hasEmptyFields = Object.values(glances).some(value => value.trim() === ''); // if user didn't change input values and remains empty

    if (hasEmptyFields) {
      setGlancesFormError(prevState => ({
        ...prevState,
        glanceDay: glances.glanceDay.trim() === '' ? 'This field is required' : null,
        glanceOrder: glances.glanceOrder.trim() === '' ? 'This field is required' : null,
        glanceTitle_En: glances.glanceTitle_En.trim() === '' ? 'This field is required' : null,
        glanceTitle_Ar: glances.glanceTitle_Ar.trim() === '' ? 'This field is required' : null,
        glanceTitle_Es: glances.glanceTitle_Es.trim() === '' ? 'This field is required' : null,
        glanceItem_En: glances.glanceItem_En.trim() === '' ? 'This field is required' : null,
        glanceItem_Ar: glances.glanceItem_Ar.trim() === '' ? 'This field is required' : null,
        glanceItem_Es: glances.glanceItem_Es.trim() === '' ? 'This field is required' : null,
      }));
      return;
    } else {
      setGlancesFormError({});
      setglanceArr([...glanceArr, { glanceDay, glanceOrder, glanceTitle_En, glanceTitle_Ar, glanceTitle_Es, glanceItem_En, glanceItem_Ar, glanceItem_Es }]);
      setGlances({ glanceDay: '', glanceOrder: '', glanceTitle_En: '', glanceTitle_Ar: '', glanceTitle_Es: '', glanceItem_En: '', glanceItem_Ar: '', glanceItem_Es: '' });
    }
  }

  async function handleClickItr() {
    const hasEmptyFields = Object.values(itinerary).some(value => value.trim() === '');
    if (hasEmptyFields) {
      setItineraryFormError(prevState => ({
        ...prevState,
        itrday: itinerary.itrday.trim() === '' ? 'This field is required' : null,
        itrtitle_En: itinerary.itrtitle_En.trim() === '' ? 'This field is required' : null,
        itrtitle_Ar: itinerary.itrtitle_Ar.trim() === '' ? 'This field is required' : null,
        itrtitle_Es: itinerary.itrtitle_Es.trim() === '' ? 'This field is required' : null,
        itrdesc_En: itinerary.itrdesc_En.trim() === '' ? 'This field is required' : null,
        itrdesc_Ar: itinerary.itrdesc_Ar.trim() === '' ? 'This field is required' : null,
        itrdesc_Es: itinerary.itrdesc_Es.trim() === '' ? 'This field is required' : null,
      }));
      return;
    } else {
      setItineraryFormError({})
      setItrArr([...itrArr, { itrday, itrtitle_En, itrtitle_Ar, itrtitle_Es, itrdesc_En, itrdesc_Ar, itrdesc_Es }])
      setItinerary({ itrday: "", itrtitle_En: "", itrtitle_Ar: "", itrtitle_Es: "", itrdesc_En: "", itrdesc_Ar: "", itrdesc_Es: "" })
    }
  }

  async function handleClickAccommodation() {
    const hasEmptyFields = Object.values(accommodations).some(value => value.trim() === '');
    if (hasEmptyFields) {
      setAccommodationsFormError(prevState => ({
        ...prevState,
        accommodationTitle_En: accommodations.accommodationTitle_En.trim() === '' ? 'This field is required' : null,
        accommodationTitle_Ar: accommodations.accommodationTitle_Ar.trim() === '' ? 'This field is required' : null,
        accommodationTitle_Es: accommodations.accommodationTitle_Es.trim() === '' ? 'This field is required' : null,
        accommodationDesc_En: accommodations.accommodationDesc_En.trim() === '' ? 'This field is required' : null,
        accommodationDesc_Ar: accommodations.accommodationDesc_Ar.trim() === '' ? 'This field is required' : null,
        accommodationDesc_Es: accommodations.accommodationDesc_Es.trim() === '' ? 'This field is required' : null,
      }));
      return;
    } else {
      setAccommodationsFormError({})
      setAccommodationsArr([...accommodationsArr, { accommodationTitle_En, accommodationTitle_Ar, accommodationTitle_Es, accommodationDesc_En, accommodationDesc_Ar, accommodationDesc_Es }])
      setAccommodations({ accommodationTitle_En: "", accommodationTitle_Ar: "", accommodationTitle_Es: "", accommodationDesc_En: "", accommodationDesc_Ar: "", accommodationDesc_Es: "" })
    }
  }

  async function handleClickNote() {
    const hasEmptyFields = Object.values(note).some(value => value.trim() === '');
    if (hasEmptyFields) {
      setNoteFormError(prevState => ({
        ...prevState,
        noteDesc_En: note.noteDesc_En.trim() === '' ? 'This field is required' : null,
        noteDesc_Ar: note.noteDesc_Ar.trim() === '' ? 'This field is required' : null,
        noteDesc_Es: note.noteDesc_Es.trim() === '' ? 'This field is required' : null,
      }));
      return;
    } else {
      setNoteFormError({})
      setNoteArr([...noteArr, { noteDesc_En, noteDesc_Ar, noteDesc_Es }])
      setNote({ noteDesc_En: "", noteDesc_Ar: "", noteDesc_Es: "" })
    }
  }

  async function handleClickInclusions() {
    const hasEmptyFields = Object.values(inclusions).some(value => typeof value === 'string' && value.trim() === '');

    if (hasEmptyFields) {
      setInclusionsFormError(prevState => ({
        ...prevState,
        inclusionDesc_En: inclusions.inclusionDesc_En.trim() === '' ? 'This field is required' : null,
        inclusionDesc_Ar: inclusions.inclusionDesc_Ar.trim() === '' ? 'This field is required' : null,
        inclusionDesc_Es: inclusions.inclusionDesc_Es.trim() === '' ? 'This field is required' : null,
      }));
      return;
    } else {
      setInclusionsFormError({})
      setInclusionsArr([...inclusionsArr, { inclusionDesc_En, inclusionDesc_Ar, inclusionDesc_Es, inclusionIncluded }])
      setInclusions({ inclusionDesc_En: "", inclusionDesc_Ar: "", inclusionDesc_Es: "", inclusionIncluded: true })
    }
  }

  const handleClick = async (e) => {
    e.preventDefault();
    const excludedKeys = ['simple-tab-0', 'simple-tab-1', 'simple-tab-2', 'simple-tab-3', 'simple-tab-4', 'maplink'];

    // Check if any fields are empty except for the excluded keys
    const hasEmptyFields = Object.entries(values).some(([key, value]) => {
      if (!excludedKeys.includes(key)) {
        return typeof value === 'string' && value.trim() === '';
      }
      return false; // Skip excluded keys
    });

    console.log('hasEmptyFields', hasEmptyFields, values);

    if (hasEmptyFields) {
      setValuesFormError(prevState => ({
        ...prevState,
        title: values.title.trim() === '' ? 'This field is required' : null,
        title_Ar: values.title_Ar.trim() === '' ? 'This field is required' : null,
        title_Es: values.title_Es.trim() === '' ? 'This field is required' : null,
        city_En: values.city_En.trim() === '' ? 'This field is required' : null,
        city_Ar: values.city_Ar.trim() === '' ? 'This field is required' : null,
        city_Es: values.city_Es.trim() === '' ? 'This field is required' : null,
        address_En: values.address_En.trim() === '' ? 'This field is required' : null,
        address_Ar: values.address_Ar.trim() === '' ? 'This field is required' : null,
        address_Es: values.address_Es.trim() === '' ? 'This field is required' : null,
        desc_En: values.desc_En.trim() === '' ? 'This field is required' : null,
        desc_Ar: values.desc_Ar.trim() === '' ? 'This field is required' : null,
        desc_Es: values.desc_Es.trim() === '' ? 'This field is required' : null,
      }));
      console.log('return', valuesFormError)
      return;
    } else {
      setValuesFormError({
        title: null, title_Ar: null, title_En: null,
        city_En: null, city_Ar: null, city_Es: null,
        address_En: null, address_Ar: null, address_Es: null,
        desc_En: null, desc_Ar: null, desc_Es: null,
      })
    }

    const photos = await Promise.all(
      files.map(async (file) => {
        if (!file) return; // Skip if file is not selected
        const data = new FormData();
        data.append('file', file);
        data.append('upload_preset', 'upload');
        const uploadRes = await axios.post(CLOUDINARY_URL, data);
        const { url } = uploadRes.data;
        return url;
      })
    );
    if (photos.length === 0) photos = values.photos

    const uploadMapFile = async (mapFile) => {
      if (!mapFile) return null; // Skip if file is not selected
      const data = new FormData();
      data.append('file', mapFile);
      data.append('upload_preset', 'upload');
      const uploadRes = await axios.post(CLOUDINARY_URL, data);
      const { url } = uploadRes.data;
      return url;
    };
    
    const maplink = await uploadMapFile(mapFile);
    if (maplink == '') maplink = values.maplink

    let highlights = highlightArr
    let glances = glanceArr
    let itinerary = itrArr
    let accommodations = accommodationsArr
    let notes = noteArr
    let inclusions = inclusionsArr
    const updatedTour = {
      ...values, highlights,
      glances, itinerary, accommodations, notes, inclusions,
      photos, maplink
    };

    axios.put(`${BASE_URL}/tours/${id}`, updatedTour)
      .then(res => {
        navigate('/tours')
      })
      .catch(err => { console.log(err); console.log('hhhhhhhh', values) });
  };


  const handleDelete = async (e,index, type) => {
    e.preventDefault()
    console.log(index)
    let updatedArr = []
    switch (type) {
      case "highlight":
        updatedArr = [...highlightArr];
        updatedArr.splice(index, 1);
        setHighlightArr(updatedArr);
        alert("Highlight item deleted");
        break;
      case "glance":
        updatedArr = [...glanceArr];
        updatedArr.splice(index, 1);
        setglanceArr(updatedArr);
        alert("Glance item deleted");
        break;
      case "itr":
        updatedArr = [...itrArr];
        updatedArr.splice(index, 1);
        setItrArr(updatedArr);
        alert("Itinerary item deleted");
        break;
      case "accommodation":
        updatedArr = [...accommodationsArr];
        updatedArr.splice(index, 1);
        setAccommodationsArr(updatedArr);
        alert("Accommodation item deleted");
        break;
      case "note":
        updatedArr = [...noteArr];
        updatedArr.splice(index, 1);
        setNoteArr(updatedArr);
        alert("Note item deleted");
        break;
      case "inclusion":
        updatedArr = [...inclusionsArr];
        updatedArr.splice(index, 1);
        setInclusionsArr(updatedArr);
        alert("Inclusion item deleted");
        break;
      default:
        console.error("Unknown type:", type);
        break;
    }
  }

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Edit Tour</h1>
        </div>
        <div className="bottom">
          {/* ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;; */}
          <div className="right">
            {/* Images */}
            <div className="left">
              {files.map((file, index) => (
                <div className="formInput" key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '2rem' }}>
                  <label>Tour Image {index + 1}</label>
                  <img
                    src={file ? file : 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'}
                    alt=""
                    id={`img-${index}`}
                    style={{ marginBottom: '1rem' }}
                  />
                  <input
                    type="file"
                    name={`img-${index}`}
                    onChange={(e) => handleFileChange(e, index)}
                  />
                </div>
              ))}
              <div className="formInput" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '2rem' }}>
                <label>Map Image</label>
                <img
                  src={mapFile ? mapFile : 'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg'}
                  alt=""
                  id="mapFile"
                  style={{ marginBottom: '1rem' }}
                />
                <input
                  type="file"
                  name="mapFile"
                  onChange={(e) => handleFileChange(e)}
                />
              </div>
            </div>
            <form>
              <div style={{ width: '50%' }}>
                <label>Featured</label>
                <select
                  id="featured"
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  value={values.featured}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>

              <div style={{ width: '47%' }}>
                <label>Country</label>
                <select
                  id="country"
                  type="number"
                  value={values.country}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                >
                  <option value={'Egypt'}>Egypt</option>
                  <option value={'Saudi Arabia'}>Saudi Arabia</option>
                  <option value={'United Arab Emirates'}>United Arab Emirates</option>
                </select>
              </div>

              {tourInputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    onChange={handleChange}
                    type={input.type}
                    value={values[input.id]}
                    placeholder={input.placeholder}
                  />
                  {valuesFormError[input.id] && <div className="errorMessage">{valuesFormError[input.id]}</div>}
                </div>
              ))}
              <div style={{ width: '30%' }}></div>
              <br /><br /><br />


              <div style={{ width: '100%' }}>
                <h3>Highlights</h3>
              </div>
              <form style={{ width: '100%', padding: '2rem', border: '1px solid grey', borderRadius: '1rem' }}>
                <div className="formInput">
                  <label>Highlights En</label>
                  <input
                    id='highlights_En'
                    name='highlights_En'
                    onChange={handleChangeHighlight}
                    type='text'
                    value={highlights.highlights_En}
                    placeholder='Enter Highlights in English'
                  />
                  {highlights_FormError.highlights_En && <div className="errorMessage">{highlights_FormError.highlights_En}</div>}
                </div>
                <div className="formInput">
                  <label>Highlights Ar</label>
                  <input
                    id='highlights_Ar'
                    name='highlights_Ar'
                    onChange={handleChangeHighlight}
                    type='text'
                    value={highlights.highlights_Ar}
                    placeholder='Enter Highlights in Arabic'
                  />
                  {highlights_FormError.highlights_Ar && <div className="errorMessage">{highlights_FormError.highlights_Ar}</div>}
                </div>
                <div className="formInput">
                  <label>Highlights Es</label>
                  <input
                    id='highlights_Es'
                    name='highlights_Es'
                    onChange={handleChangeHighlight}
                    type='text'
                    value={highlights.highlights_Es}
                    placeholder='Enter Highlights in Spanish'
                  />
                  {highlights_FormError.highlights_Es && <div className="errorMessage">{highlights_FormError.highlights_Es}</div>}
                </div>
                <button onClick={handleClickHighlights}>Add Highlight</button>

                <table bglanceOrder={1} width="100%" cellPadding={10} className="descriptionTable">
                  <colgroup>
                    <col style={{ width: '70%' }} />
                    <col style={{ width: '30%' }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>Highlight</th>
                      <th>Action</th>
                    </tr>
                    {
                      highlightArr.map((info, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <li>{info.highlights_En}</li>
                              <li>{info.highlights_Ar}</li>
                              <li>{info.highlights_Es}</li>
                            </td>
                            <td>
                              <button
                                className="smallButton"
                                style={{
                                  width: '100px !important',
                                  padding: '5px !important',
                                  backgroundColor: '#00AAAA !important',
                                  fontWeight: '400 !important',
                                  marginTop: '5px !important'
                                }}
                                onClick={(e) => handleDelete(e,index, "highlight")}>
                                Delete
                              </button>
                            </td>
                          </tr>
                        )
                      }
                      )
                    }
                  </tbody>
                </table>

              </form>

              <button onClick={handleClick} style={{ width: '100%' }}>Submit</button>
            </form>
            <div className="tabs">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ bglanceOrderBottom: 1, bglanceOrderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="At a glance" {...a11yProps(0)} />
                    <Tab label="Itinerary" {...a11yProps(1)} />
                    <Tab label="accommodationss" {...a11yProps(2)} />
                    <Tab label="Notes" {...a11yProps(3)} />
                    <Tab label="Inclusions" {...a11yProps(4)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <form>
                    {GlanceInputs.map((item, index) => {
                      return (
                        <div className={item.id === 'glanceDay' || item.id === 'glanceOrder' ? 'formInput-width-50' : 'formInput'} key={index}>
                          <label>{item.label}</label>
                          <input
                            id={item.id}
                            name={item.id}
                            placeholder={item.placeholder}
                            value={glances[item.id]}
                            onChange={handleChangeGlance}
                            style={{ width: "100%" }}
                            type={item.type}
                            min={item.type === 'number' ? '1' : undefined}
                          />
                          {glancesFormError[item.id] && <div className="errorMessage">{glancesFormError[item.id]}</div>}
                        </div>
                      )
                    })}
                  </form>
                  <div></div>
                  <br />
                  <button onClick={handleClickGalance}>Add Topic</button>
                  <br />
                  <br />

                  <table bglanceOrder={1} width="100%" cellPadding={10} className="descriptionTable">
                    <tbody>
                      <tr>
                        <th>Glance Day</th>
                        <th>Glance Title</th>
                        <th>Glance Item</th>
                        <th>Glance Order</th>
                        <th>Action</th>
                      </tr>
                      {
                        glanceArr.map((info, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {info.glanceDay}
                              </td>
                              <td>
                                <li>{info.glanceTitle_En}</li>
                                <li>{info.glanceTitle_Ar}</li>
                                <li>{info.glanceTitle_Es}</li>
                              </td>
                              <td>
                                <li>{info.glanceItem_En}</li>
                                <li>{info.glanceItem_Ar}</li>
                                <li>{info.glanceItem_Es}</li>
                              </td>
                              <td>
                                {info.glanceOrder}
                              </td>
                              <td>
                                <button onClick={(e) => handleDelete(e,index, "glance")}>Delete</button>
                              </td>
                            </tr>
                          )
                        }
                        )
                      }
                    </tbody>
                  </table>

                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <form>
                    {ItrInputs.map((item, index) => {
                      return (
                        <div className={item.id === 'itrday' ? 'formInput-width-100' : 'formInput'} key={index}>
                          <label>{item.label}</label>
                          <input
                            id={item.id}
                            name={item.id}
                            placeholder={item.placeholder}
                            value={itinerary[item.id]}
                            onChange={handleChangeItr}
                            style={{ width: "100%" }}
                            type={item.type}
                            min={item.type === 'number' ? '1' : undefined}
                          />
                          {itineraryFormError[item.id] && <div className="errorMessage">{itineraryFormError[item.id]}</div>}
                        </div>
                      )
                    })}
                    {ItrTextarea.map((item, index) => {
                      return (
                        <div className="formInput" key={index}>
                          <label>{item.label} </label>
                          <textarea
                            id={item.id}
                            rows="4" cols={100}
                            name={item.id}
                            placeholder={item.placeholder}
                            value={itinerary[item.id]}
                            onChange={handleChangeItr}
                            style={{ width: "100%" }}
                            type="textarea"
                          />
                          {itineraryFormError[item.id] && <div className="errorMessage">{itineraryFormError[item.id]}</div>}
                        </div>
                      )
                    })}
                  </form>
                  <div></div>
                  <br />
                  <button onClick={handleClickItr}>Add Topic</button>
                  <br />
                  <br />

                  <table bglanceOrder={1} width="100%" cellPadding={10} className="descriptionTable">
                    <tbody>
                      <tr>
                        <th>Glance Day</th>
                        <th>Itinerary Title</th>
                        <th>Description</th>
                        <th>Action</th>
                      </tr>
                      {
                        itrArr.map((info, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {info.itrday}
                              </td>
                              <td>
                                <li>{info.itrtitle_En}</li>
                                <li>{info.itrtitle_Ar}</li>
                                <li>{info.itrtitle_Es}</li>
                              </td>
                              <td>
                                <li>{info.itrdesc_En}</li>
                                <li>{info.itrdesc_Ar}</li>
                                <li>{info.itrdesc_Es}</li>
                              </td>
                              <td>
                                <button onClick={(e) => handleDelete(e,index, "itr")}>Delete</button>
                              </td>
                            </tr>
                          )
                        }
                        )
                      }
                    </tbody>
                  </table>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <form>
                    {AccommodationInputs.map((item, index) => {
                      return (
                        <div className="formInput" key={index}>
                          <label>{item.label}</label>
                          <input
                            id={item.id}
                            name={item.id}
                            placeholder={item.placeholder}
                            value={accommodations[item.id]}
                            onChange={handleChangeAccommodations}
                            style={{ width: "100%" }}
                            type={item.type}
                            min={item.type === 'number' ? '1' : undefined}
                          />
                          {accommodationsFormError[item.id] && <div className="errorMessage">{accommodationsFormError[item.id]}</div>}
                        </div>
                      )
                    })}
                    {AccommodationTextarea.map((item, index) => {
                      return (
                        <div className="formInput" key={index}>
                          <label>{item.label} </label>
                          <textarea
                            id={item.id}
                            rows="4" cols={100}
                            name={item.id}
                            placeholder={item.placeholder}
                            value={accommodations[item.id]}
                            onChange={handleChangeAccommodations}
                            style={{ width: "100%" }}
                            type="textarea"
                          />
                          {accommodationsFormError[item.id] && <div className="errorMessage">{accommodationsFormError[item.id]}</div>}
                        </div>
                      )
                    })}

                  </form>
                  <div></div>
                  <br />
                  <button onClick={handleClickAccommodation}>Add Topic</button>
                  <br />
                  <br />

                  <table bglanceOrder={1} width="100%" cellPadding={10} className="descriptionTable">
                    <tbody>
                      <tr>
                        <th>Accommodation Title</th>
                        <th>Accommodation Description</th>
                        <th>Action</th>
                      </tr>
                      {
                        accommodationsArr.map((info, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <li>{info.accommodationTitle_En}</li>
                                <li>{info.accommodationTitle_Ar}</li>
                                <li>{info.accommodationTitle_Es}</li>
                              </td>
                              <td>
                                <li>{info.accommodationDesc_En}</li>
                                <li>{info.accommodationDesc_Ar}</li>
                                <li>{info.accommodationDesc_Es}</li>
                              </td>
                              <td>
                                <button onClick={(e) => handleDelete(e,index, "accommodation")}>Delete</button>
                              </td>
                            </tr>
                          )
                        }
                        )
                      }
                    </tbody>
                  </table>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  <form>
                    {NoteTextarea.map((item, index) => {
                      return (
                        <div className="formInput" key={index}>
                          <label>{item.label} </label>
                          <textarea
                            id={item.id}
                            rows="4" cols={100}
                            name={item.id}
                            placeholder={item.placeholder}
                            value={note[item.id]}
                            onChange={handleChangeNote}
                            style={{ width: "100%" }}
                            type="textarea"
                          />
                          {noteFormError[item.id] && <div className="errorMessage">{noteFormError[item.id]}</div>}
                        </div>
                      )
                    })}
                  </form>
                  <div></div>
                  <br />
                  <button onClick={handleClickNote}>Add Note</button>
                  <br />
                  <br />

                  <table bglanceOrder={1} width="100%" cellPadding={10} className="descriptionTable">
                    <tbody>
                      <tr>
                        <th>Notes</th>
                        <th>Action</th>
                      </tr>
                      {
                        noteArr.map((info, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <li>{info.noteDesc_En}</li>
                                <li>{info.noteDesc_Ar}</li>
                                <li>{info.noteDesc_Es}</li>
                              </td>
                              <td>
                                <button onClick={(e) => handleDelete(e,index, "note")}>Delete</button>
                              </td>
                            </tr>
                          )
                        }
                        )
                      }
                    </tbody>
                  </table>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                  <label>Inclusion: </label>
                  <form>
                    <div>
                      <input
                        id="inclusionIncluded"
                        name="inclusionIncluded"
                        checked={inclusions.inclusionIncluded}
                        onChange={handleChangeInclusions}
                        type="checkbox"
                        style={{ display: "block", width: '100%' }}
                      />

                    </div>
                    {Inclusiontarea.map((item, index) => {
                      return (
                        <div className="formInput" key={index}>
                          <label>{item.label} </label>
                          <textarea
                            id={item.id}
                            rows="4" cols={100}
                            name={item.id}
                            placeholder={item.placeholder}
                            value={inclusions[item.id]}
                            onChange={handleChangeInclusions}
                            style={{ width: "100%" }}
                            type="textarea"
                          />
                          {inclusionsFormError[item.id] && <div className="errorMessage">{inclusionsFormError[item.id]}</div>}
                        </div>
                      )
                    })}
                  </form>
                  <div></div>
                  <br />
                  <button onClick={handleClickInclusions}>Add Inclusion</button>
                  <br />
                  <br />

                  <table bglanceOrder={1} width="100%" cellPadding={10} className="descriptionTable">
                    <tbody>
                      <tr>
                        <th>Inclusions</th>
                        <th>Included</th>
                        <th>Action</th>
                      </tr>
                      {
                        inclusionsArr.map((info, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <li>{info.inclusionDesc_En}</li>
                                <li>{info.inclusionDesc_Ar}</li>
                                <li>{info.inclusionDesc_Es}</li>
                              </td>
                              <td>
                                {info.inclusionIncluded ? 'Included' : 'Not Included'}
                              </td>
                              <td>
                                <button onClick={(e) => handleDelete(e,index, "inclusion")}>Delete</button>
                              </td>
                            </tr>
                          )
                        }
                        )
                      }
                    </tbody>
                  </table>
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateTour;
